import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import typography from "../styles/typography"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { BodyMedium, Header3 } from "../components/typography"

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.creamLight};
  color: ${colors.creamLight};

  .header {
    padding: 40px;
    min-height: 117px;

    border-bottom: 1px solid ${colors.creamLight};

    > ${Header3} {
      max-width: 1440px;
      text-align: center;
      margin: 0 auto;

      @media ${breakpoints.desktop} {
        ${typography.h2.desktop}
      }
    }
  }

  .excerpt {
    display: flex;

    padding: 40px;

    border-bottom: 1px solid ${colors.creamLight};

    > ${BodyMedium} {
      display: flex;
      align-self: end;
      width: 350px;
    }
  }

  .content {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
    white-space: pre-wrap;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;

  margin-left: 20px;

  @media ${breakpoints.tablet} {
    border: 0;

    &:after {
      display: none;
    }

    .gatsby-image-wrapper {
      width: auto;
      height: 480px;
    }
  }
`

export const query = graphql`
  query Blog($id: String) {
    contentfulBlog(id: { eq: $id }) {
      blogTitle
      blogExcerpt
      blogFeaturedImage {
        gatsbyImage(height: 480, placeholder: BLURRED)
        description
      }
      blogContent {
        internal {
          content
        }
      }
    }
  }
`

export default function BlogTemplate({ data }) {
  const { contentfulBlog: blog } = data

  return (
    <Layout>
      <Seo title={blog.blogTitle} />
      <Wrapper>
        <div className="header">
          <Header3>{blog.blogTitle}</Header3>
        </div>
        <div className="excerpt">
          <BodyMedium>{blog.blogExcerpt}</BodyMedium>
          <ImageWrapper>
            <GatsbyImage
              image={blog.blogFeaturedImage.gatsbyImage}
              alt={blog.blogFeaturedImage.description ?? "Featured Image"}
            />
          </ImageWrapper>
        </div>
        <div className="content">
          <BodyMedium>{blog.blogContent.internal.content}</BodyMedium>
        </div>
      </Wrapper>
    </Layout>
  )
}
